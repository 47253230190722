exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-index-jsx": () => import("./../../../src/templates/AboutPage/index.jsx" /* webpackChunkName: "component---src-templates-about-page-index-jsx" */),
  "component---src-templates-contacts-page-index-jsx": () => import("./../../../src/templates/ContactsPage/index.jsx" /* webpackChunkName: "component---src-templates-contacts-page-index-jsx" */),
  "component---src-templates-doctor-page-index-jsx": () => import("./../../../src/templates/DoctorPage/index.jsx" /* webpackChunkName: "component---src-templates-doctor-page-index-jsx" */),
  "component---src-templates-home-page-index-jsx": () => import("./../../../src/templates/HomePage/index.jsx" /* webpackChunkName: "component---src-templates-home-page-index-jsx" */),
  "component---src-templates-legal-page-index-jsx": () => import("./../../../src/templates/LegalPage/index.jsx" /* webpackChunkName: "component---src-templates-legal-page-index-jsx" */),
  "component---src-templates-service-page-index-jsx": () => import("./../../../src/templates/ServicePage/index.jsx" /* webpackChunkName: "component---src-templates-service-page-index-jsx" */),
  "component---src-templates-services-page-index-jsx": () => import("./../../../src/templates/ServicesPage/index.jsx" /* webpackChunkName: "component---src-templates-services-page-index-jsx" */),
  "component---src-templates-team-page-index-jsx": () => import("./../../../src/templates/TeamPage/index.jsx" /* webpackChunkName: "component---src-templates-team-page-index-jsx" */),
  "component---src-templates-tech-page-index-jsx": () => import("./../../../src/templates/TechPage/index.jsx" /* webpackChunkName: "component---src-templates-tech-page-index-jsx" */)
}

