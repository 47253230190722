import { createGlobalStyle } from 'styled-components';
import { easeOutTiming } from './animation';

export const MOBILE_VIEWPORT = 540;
export const TABLET_PORTRAIT_VIEWPORT = 820;
export const TABLET_LANDSCAPE_VIEWPORT = 1180;
export const LAPTOP_VIEWPORT = 1280;
export const DESKTOP_VIEWPORT = 1440;

// media queries
export const breakpoints = {
  mobile: `${MOBILE_VIEWPORT}px`,
  tablet: `${TABLET_PORTRAIT_VIEWPORT}px`,
  tabletLandscape: `${TABLET_LANDSCAPE_VIEWPORT}px`,
  laptop: `${LAPTOP_VIEWPORT}px`,
  desktop: `${DESKTOP_VIEWPORT}px`,
};

export const GlobalStyle = createGlobalStyle`
  :root {
    // fonts
    --base-font: Inter, sans-serif;

    --weight-regular: 400;
    --weight-semi-bold: 600;
    --weight-bold: 700;

    // global colors
    --primary-color: #4780e3;
    --secondary-color: #f5d65a;

    --color-white: #fff;

    --color-dark-01: #0c0C0e;
    --color-dark-02: #191819;
    --color-dark-03: #373637;
    --color-dark-04: #211f21;
    --color-dark-05: #2a292a;
    --color-dark-06: #676567;

    --color-error: #e9355d;

    // animation
    --transition-delay: .3s;
    --transition-duration: .3s;
    --timing-function: ease-out;

    // variables
    --gap: 1.5rem;

    --border-radius: 0.25rem;
    --border-width: .0625rem;

    // header
    --z-index-header: 2;

    --header-height: 5.4375rem;
    --header-height-tablet: 4.25rem;
    --header-height-mobile: 3.3125rem;

    --header-box-shadow: 0rem 0.25rem 1.5rem #082f3c;
    --header-background-color: var(--color-dark-02);

    // footer
    --footer-background-color: var(--color-dark-02);

    // container
    --container-padding: 7.5rem;
    --container-padding-laptop: 5rem;
    --container-padding-laptop-small: 2.5rem;
    --container-padding-mobile: 1rem;

    // buttons
    --primary-button-color: var(--color-white);
    --primary-button-background: var(--primary-color);
    --primary-button-border-color: var(--primary-color);

    --light-button-color: var(--color-dark-02);
    --light-button-background: var(--color-white);
    --light-button-border-color: var(--color-white);

    --dark-button-color: var(--color-white);
    --dark-button-background: var(--color-dark-04);
    --dark-button-border-color: var(--color-dark-04);

    --transparent-button-color: var(--color-white);
    --transparent-button-background: transparent;
    --transparent-button-border-color: var(--color-white);

    --play-button-icon-color: var(--color-white);
    --play-button-first-background-color: var(--color-dark-01);
    --play-button-second-background-color: var(--primary-color);
    
    // variables
    --form-border-radius: 1rem;
    --form-gap: 1.875rem;
    --form-gap-mobile: 1rem;

    --underline-width: .125rem;

    // theme
    --page-background-color: #F2F2F7;
    --card-background-color: var(--color-dark-02);
    --social-color: var(--color-white);
    --social-border-color: var(--color-white);
    --form-background-color: var(--color-dark-02);
    --input-background-color: var(--color-dark-05);
    --input-placeholder-color: var(--color-dark-06);
    --input-color: var(--color-white);
    }


    ::selection {
      color: var(--primary-color);
      background: var(--secondary-color);
    }

    * {
      -webkit-tap-highlight-color: transparent;
    }

    *,
    *::after,
    *::before {
      box-sizing: border-box;
    }

    html {
      height: 100%;
      font-size: 100%;
      line-height: 1.5;
      font-family: var(--base-font);
      background-color: #FAF7F9;
      scroll-behavior: smooth;
      padding: 0;
      margin: 0;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: var(--weight-regular);
      color: #030D41;
      height: 100%;
      margin: 0;
    }

    #___gatsby {
      height: 100%;
    }

    #gatsby-focus-wrapper {
      flex-direction: column;
      min-height: 100%;
      display: flex;
    }

    header,
    footer {
      flex: 0 0 auto;
    }

    article {
      flex: 1 0 auto;
    }

    p,
    h1,
    h2,
    h3 {
      margin: 0;
      padding: 0;
    }

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    button {
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
      appearance: none;
      background: none;
    }

    input,
    button,
    select,
    textarea {
      font-family: inherit;
      line-height: 1.15;
      font-size: 100%;
      margin: 0;
    }

    svg {
      display: -webkit-box;
    }

    a {
      line-height: 1.5rem;
      text-decoration: none;
      color: #030D41;
      transition: color ${easeOutTiming};
      font-weight: var(--weight-semi-bold);


      &.activeClassLink {
        cursor: default;
      }
    }

    b,
    strong {
      font-weight: var(--weight-bold);
    }
`;
