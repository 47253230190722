import { GlobalStyle } from './src/styles/global';

export const onClientEntry = () => {
  const userLang = navigator.language.slice(0, 2);

  if (userLang !== 'uk' && !window.location.pathname.startsWith('/en')) {
    window.location.pathname = `/en${window.location.pathname}`;
  }
};

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
);
